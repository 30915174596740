<template>
  <div :style="getIsMobile ? 'width:330px;height:130px' : ''">
    <p :style="getStyle">
      {{ data.content }}
    </p>
  </div>
</template>

<script>
import userPermissionsHelper from "@/mixins/userPermissionsHelper";
import { mapGetters } from "vuex";
export default {
  mixins: [userPermissionsHelper],
  name: "templates-formComponentsExecute-ParagraphExecute",
  props: ["data"],
  data() {
    return {};
  },
  mounted() {
    //if (this.data.default_value) {
    //  this.form[this.data.key] = this.data.default_value;
    //}
  },
  computed: {
    ...mapGetters("navigationOpen", ["getIsMobile"]),
    getStyle() {
      return (
        `height:${this.data.height}px;width:${this.data.width}px;overflow:auto;` +
        this.getElementStyle
      );
    },
  },
};
</script>

<style lang="scss">
.resposive-font {
  font-size: 1.2vw;
  line-height: 1.5;
}

.paragraphScroll {
  height: 250px;
  overflow-y: scroll;
  overflow-x: scroll;
  border-top: 1px ridge;
  border-left: 1px ridge;
  border-bottom: 1px ridge;
  margin-right: 5px;
}
.paragraphScroll::-webkit-scrollbar {
  width: 0.7em;
}
.paragraphScroll::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  background: #e1e1e1;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}
</style>
